import IMask from 'imask'

function toggleCompleted (mask, input) {
  mask.on('complete', function () {
    input.dataset.completed = 'true'
  })

  mask.on('accept', function () {
    input.dataset.completed = 'false'
  })
}

function resetValue (input) {
  input.value = ''
}

function updateValue (mask) {
  mask.updateValue()
}

function resetCompleted (input) {
  input.dataset.completed = 'false'
}

function initialMaskedNumber (input) {
  const mask = new IMask(input, {
    mask: '+{7} (000) 000-00-00',
    lazy: true,
    prepare: (appended, masked) => {
      if (appended === '8' && masked.value === '') {
        return ''
      }

      return appended
    }
  })
  const reset = () => {
    resetValue(input)
    resetCompleted(input)
    updateValue(mask)
  }

  toggleCompleted(mask, input)

  return {
    mask,
    input,
    reset
  }
}

export {
  initialMaskedNumber
}
