import { changeInput, isValidate, resetInput } from '%modules%/input/input'
import { bemCaller } from '%helpers%/bem-caller'
import { initialMaskedNumber } from '%helpers%/masked'
import { isSubmitting, offSubmitting } from '%helpers%/submiting-form'
import { openModalGetById } from '%modules%/modal-parent/modal-parent'

bemCaller('.modal-body-feedback', init)
function init (block) {
  const inputList = [...block.querySelectorAll('input')]
  const inputPhone = inputList.filter(input => input.dataset.type === 'phone')[0]

  const { reset: resetInputPhone } = initialMaskedNumber(inputPhone)

  block.addEventListener('reset', function (event) {
    resetInputPhone()
    inputList.forEach(input => resetInput(input))
  })

  block.addEventListener('input', function (event) {
    for (const input of inputList) {
      changeInput(input)
    }
  })

  block.addEventListener('submit', async function (event) {
    event.preventDefault()

    if (isSubmitting(this)) return

    let isError = false
    for (const input of inputList) {
      if (!isValidate(input)) isError = true
    }

    if (isError === false) {
      try {
        const response = await fetch('/mail.php', {
          method: 'POST',
          body: new FormData(this)
        })
        console.log(response)

        if (!response.ok) {
          throw new Error('Не ok, информация в предыдущем логе')
        }

        openModalGetById('successful')
        offSubmitting(this)
      } catch (e) {
        openModalGetById('error')
        offSubmitting(this)
      }
    } else {
      offSubmitting(this)
    }
  })
}
