import Swiper, { Pagination, Navigation } from 'swiper'
import { bemCaller } from '%helpers%/bem-caller'
import { getDomEl } from '%modules%/slider-nav/slider-nav'

bemCaller('.reviews', init)
function init (block) {
  const swiper = block.querySelector('.swiper')
  const { prevBtn, nextBtn, dots } = getDomEl(block)

  // eslint-disable-next-line
  new Swiper(swiper, {
    modules: [
      Pagination,
      Navigation
    ],
    slidesPerView: 2,
    spaceBetween: 20,
    pagination: {
      el: dots
    },
    navigation: {
      prevEl: prevBtn,
      nextEl: nextBtn
    },
    breakpoints: {
      0: {
        slidesPerView: 1
      },
      1024: {
        slidesPerView: 2
      }
    }
  })
}
