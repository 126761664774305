function resetInput (input) {
  input.classList.remove('input--error')
  input.value = ''
}

function changeInput (input) {
  input.classList.remove('input--error')
}

function setError (input) {
  input.classList.add('input--error')
}

function isValidate (input) {
  let error = false

  if (input.dataset.type === 'phone' && !validPhone(input)) {
    setError(input)
    error = true
  }

  if (input.dataset.type === 'mail' && !validEmail(input.value)) {
    setError(input)
    error = true
  }

  if (input.dataset.type === 'text' && !validText(input)) {
    setError(input)
    error = true
  }

  return !error
}

function validEmail (email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

function validPhone (input) {
  return input.dataset.completed === 'true'
}

function validText (input) {
  return input.value.length >= 3
}

export {
  resetInput,
  changeInput,
  isValidate
}
