import { onDelegate } from '%helpers%/delegate'

onDelegate('click', '[data-anchor]', function (event) {
  event.preventDefault()

  const ident = this.getAttribute('data-anchor-ident')
  const gap = this.getAttribute('data-anchor-gap')
  const animation = this.getAttribute('data-anchor-animation')

  scrollTo({
    ident: ident,
    gap: gap === 'true',
    animation: animation === 'true'
  })
})

function scrollTo ({ ident, gap, animation }) {
  const section = document.querySelector(`[id="${ident}"]`)
  const gapValue = gap ? 40 : 0

  if (!section) return

  document.body.scrollBy({
    top: section.getBoundingClientRect().top - gapValue,
    behavior: animation ? 'smooth' : 'auto'
  })
}
