import { onDelegate } from '%helpers%/delegate'

onDelegate('click', '.card-faq__panel', function (event) {
  const card = this.closest('.card-faq')
  const body = card.querySelector('.card-faq__body')
  const plus = card.querySelector('.card-faq__plus')
  const panel = card.querySelector('.card-faq__panel')

  if (card.classList.contains('card-faq--open')) {
    close(card, body, plus, panel)
  } else {
    closeAll()
    open(card, body, plus, panel)
  }
})

function closeAll () {
  document.querySelectorAll('.card-faq').forEach(card => {
    const body = card.querySelector('.card-faq__body')
    const plus = card.querySelector('.card-faq__plus')
    const panel = card.querySelector('.card-faq__panel')

    close(card, body, plus, panel)
  })
}

function open (card, body, plus, panel) {
  card.classList.add('card-faq--open')
  body.classList.add('card-faq__body--open')
  plus.classList.add('card-faq__plus--open')
  panel.classList.add('card-faq__panel--open')

  body.style.height = `${body.scrollHeight}px`
}

function close (card, body, plus, panel) {
  card.classList.remove('card-faq--open')
  body.classList.remove('card-faq__body--open')
  plus.classList.remove('card-faq__plus--open')
  panel.classList.remove('card-faq__panel--open')

  body.style.height = ''
}
