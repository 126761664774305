import { onDelegate } from '%helpers%/delegate'

onDelegate('click', '.price__toggler--show-price', function (event) {
  this.closest('.price').classList.add('price--open')
})

onDelegate('click', '.price__toggler--hide-price', function (event) {
  this.closest('.price').classList.remove('price--open')
  scrollTo(this.closest('.price'))
})

function scrollTo (section) {
  document.body.scrollBy({
    top: section.getBoundingClientRect().top - 40
  })
}
