// Импорт библиотек
import 'img-comparison-slider/dist/index'
// ---

// Импорт универсальных скриптов
import './common/anchor'
// ---

// Импорт модулей
import './import/modules'
// ---
