import { onDelegate } from '%helpers%/delegate'

onDelegate('click', '[data-open-modal]', function (event) {
  openModalGetById(this.dataset.openModal)
})

onDelegate('click', '[data-close-modal]', function (event) {
  closeModalAll()
})

onDelegate('click', '.modal-parent', function ({ target }) {
  if (target.classList.contains('modal-parent')) {
    closeModalAll()
  }
})

function toggleOverlay (is) {
  const overlay = document.querySelector('.overlay')

  const key = (is === 'open') ? 'add' : 'remove'
  overlay.classList[key]('overlay--show')
}

function closeModalAll () {
  const listModal = document.querySelectorAll('.modal-parent')

  for (const modal of listModal) {
    callResetInnerForm(modal)
    modal.classList.remove('modal-parent--open')
  }

  toggleOverlay('close')
}

function openModalGetById (id) {
  closeModalAll()

  const modal = document.querySelector(`.modal-parent[data-modal-ident="${id}"]`)

  toggleOverlay('open')
  modal.classList.add('modal-parent--open')
}

function callResetInnerForm (modal) {
  const form = modal.querySelector('form')

  if (form) {
    form.dispatchEvent(new Event('reset', { bubbles: true }))
  }
}

export {
  closeModalAll,
  openModalGetById
}
